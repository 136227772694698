body {
  font-family: Quicksand-Medium;
}

.slide-bg {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;
}

.banner {
  padding-top: 240px;
  padding-bottom: 125px;
}

.link {
  color: #dedede;

  &:hover {
    color: #ffffff !important;
    text-decoration: none;
  }

  &.link-green {
    &:hover {
      color: $primary !important;
      text-decoration: none;
    }
  }
}

.icon-spin {
  animation: icon-spin 1.4s infinite linear;
}

@keyframes icon-spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(359deg);
  }
}

// Customs

.bg-grey {
  background-color: $gray-oslo;
}

.bg-green-feta {
  background-color: $green-feta;
}

.bg-white-alabaster {
  background-color: $white-alabaster;
}

.bg-dark-transparent {
  background-color: rgba(0, 0, 0, 0.6);
}

body,
.table-responsive {
  @include scrollable();
}

.box-primary-outline {
  border: 2px solid $primary;
  border-radius: 10px;
  padding: 10px;
}

.box-primary {
  background-color: $primary;
  border-radius: 10px;
  color: white;
  padding: 10px;
}

.usage-policy {
  height: 600px;
  overflow-y: scroll;
  @include scrollable();
}

.box-shadow-none {
  box-shadow: none !important;
}

.rounded-25 {
  border-radius: 25px !important;
}

.rounded-right- {
  &0 {
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }

  &25 {
    border-top-right-radius: 25px !important;
    border-bottom-right-radius: 25px !important;
  }
}

.border-right-md-1 {
  border-right: 1px solid $gray-alto;
}

embed {
  @include scrollable();
}
