@mixin scrollable {
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
    height: 8px;
    border-radius: 10px;
    background-color: $green-feta;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: $primary;
  }
}
