.homepage {
  &__title {
    font-size: 130px;
    font-weight: 100;
    line-height: 110px;
    margin-bottom: 50px;
    color: #ffffff;
  }

  &__quote {
    font-size: 26px;
  }
}
