.navbar-default {
  background: rgba(0, 0, 0, 0.75);
  @media (max-width: $md) {
    padding-right: unset;
  }

  .img-brand {
    width: 125px;
  }

  .nav-item a {
    color: #ffffff;
  }
}
