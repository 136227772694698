.logout-page {
  .logout-content {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    // position: absolute;
    // left: 44%;
  }
}

.fade-from-right {
  animation: fadeFromRight 0.4s linear;
}

@keyframes fadeFromRight {
  from {
    opacity: 0.1;
    margin-left: 60px;
  }
  to {
    opacity: 1;
    margin-left: 0px;
  }
}
