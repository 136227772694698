.slider {
  background-color: black;

  .custom-slick-arrow {
    position: absolute;
    font-size: 50px;
    top: 50%;
    z-index: 70;
    color: #ffffff;
    cursor: pointer;
    opacity: 0.5;

    &:hover {
      opacity: 1;
    }

    &.custom-slick-prev {
      left: 5%;
    }

    &.custom-slick-next {
      right: 5%;
    }
  }
}
