.login {
  &__banner {
    min-height: 100vh;
  }

  background-color: $sidebar-background;

  height: 100vh;
  width: 100vw;
  position: absolute;
  overflow: hidden;

  .image-background {
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0.15;

    background-repeat: no-repeat;
    background-position-x: 0px;
    background-position-y: 100px;

    background-size: 90rem;
    @media (min-device-width: $sm) {
      background-size: 45rem;
    }
  }
}
