.news-slide {
  position: relative;
  font-size: 22px;
  justify-content: center;
  min-height: 300px;
  background-size: 100% 100%;
  background-repeat: no-repeat;

  .news-description {
    &__bg {
      background-color: $black-mine-shaft;
      color: white;
      opacity: 0.5;
      position: absolute;
      bottom: 0px;
      width: 100%;
    }

    &__text {
      opacity: 1 !important;
    }
  }
}
