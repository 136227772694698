.video-player {
  position: relative;

  margin-bottom: -10px;

  &__button {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    img {
      // width: 50%;
      height: 50%;
    }
  }
}
