.modal {
  .modal-enlogy {
    .modal-content {
      border: unset;
      border-radius: 10px;

      .modal-header {
        border-bottom: unset;
        background-color: $primary;
        color: #ffffff;

        .modal-title {
          text-align: center;
        }

        .close {
          color: #ffffff;
        }
      }

      .modal-body {
        padding: 1.7rem;
      }
    }

    &.modal-transparent {
      button.close {
        opacity: 1;
      }

      .modal-content {
        background-color: transparent;

        .modal-header {
          background-color: transparent;
        }
      }
    }

    &.no-max-width {
      max-width: unset;
    }
  }
}

.modal-backdrop {
  width: 100%;
  height: 100%;
}
