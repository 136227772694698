.tabs-enlogy {
  border-bottom: unset;
  // flex-wrap: nowrap;
  // overflow-x: scroll;
  // overflow-y: hidden;

  &::-webkit-scrollbar {
    display: none;
  }

  .nav-item {
    white-space: nowrap;

    .nav-link {
      border: unset;
      color: $black-tundora;

      &.active {
        color: $primary;
        border-bottom: 2px solid $primary;
      }
    }
  }
}
