@import '../assets//fonts/bebas/Bebas-Regular.ttf';
@import '../assets//fonts/quicksand/Quicksand-Bold.ttf';
@import '../assets//fonts/quicksand/Quicksand-Light.ttf';
@import '../assets//fonts/quicksand/Quicksand-Medium.ttf';
@import '../assets//fonts/quicksand/Quicksand-Regular.ttf';
@import '../assets//fonts/quicksand/Quicksand-SemiBold.ttf';

@font-face {
  font-family: 'Bebas-Regular';
  src: local('Bebas-Regular'),
    url(../assets/fonts/bebas/Bebas-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Quicksand-Bold';
  src: local('Quicksand-Bold'),
    url(../assets/fonts/quicksand/Quicksand-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Quicksand-Light';
  src: local('Quicksand-Light'),
    url(../assets/fonts/quicksand/Quicksand-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'Quicksand-Medium';
  src: local('Quicksand-Medium'),
    url(../assets/fonts/quicksand/Quicksand-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'Quicksand-Regular';
  src: local('Quicksand-Regular'),
    url(../assets/fonts/quicksand/Quicksand-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Quicksand-SemiBold';
  src: local('Quicksand-SemiBold'),
    url(../assets/fonts/quicksand/Quicksand-SemiBold.ttf) format('truetype');
}

.bebas-regular {
  font-family: Bebas-Regular;
}

.quicksand-bold {
  font-family: Quicksand-Bold;
}

.quicksand-light {
  font-family: Quicksand-Light;
}

.quicksand-medium {
  font-family: Quicksand-Medium;
}

.quicksand-regular {
  font-family: Quicksand-Regular;
}

.quicksand-semibold {
  font-family: Quicksand-SemiBold;
}
