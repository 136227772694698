.typeahead {
  &__menu {
    z-index: 3;
    position: absolute;
    background-color: white;
    border: 1px solid lightgray;
    border-radius: 3px;
    width: 95.5%;
    max-height: 450px;
    overflow-y: scroll;

    &item {
      padding: 10px;
      &.highlight {
        background-color: $primary !important;
        color: white;

        &.disabled {
          background-color: #eaeaea !important;
        }
      }

      &.selected {
        background-color: lightgray;
      }

      &.disabled {
        color: lightgray;
        cursor: no-drop;
      }
    }
  }

  &__input {
    border-right: unset;

    &:focus {
      box-shadow: none !important;
      border: 1px grey solid;
      border-right: unset;

      & + .input-group-prepend .typeahead__button {
        border: 1px grey solid;
        border-left: unset;
      }
    }
  }

  .typeahead__button {
    z-index: 0;
    box-shadow: none !important;
    background-color: $background-color;
    color: grey;
    border-color: lightgrey;
    border-left: unset;

    &.is-invalid {
      border: 1px solid indianred;
      border-left: unset;
    }
  }
}
