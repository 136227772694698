.breadcrumb-enlogy {
  ol.breadcrumb {
    padding: unset;
    background-color: unset;

    li.breadcrumb-item {
      color: $primary;
      a {
        color: $primary;
        text-decoration: none;

        &:hover {
          color: darken($primary, 15%);
        }
      }

      & + .breadcrumb-item {
        &::before {
          content: '›';
        }
      }
    }
  }
}
