.editor-wrapper {
  &.invalid {
    .ck-content {
    }
  }

  .ck-focused {
  }
}

.editor-wrapper.invalid
  > div.ck.ck-reset.ck-editor.ck-rounded-corners
  > div.ck.ck-editor__main
  > .ck.ck-content {
  border: 1px solid $red-punch;

  &:focus {
    outline: none;
    box-shadow: inset 0 0 0.2rem $red-punch;
  }
}

.editor-wrapper {
  #no-toolbar {
    border: unset;
  }
  .quill {
    &.readonly {
      .ql-container {
        border: unset;
      }
    }
  }
}
