.pagination {
  .page-item {
    .page-link {
      color: $primary;
      z-index: unset;

      &.disabled {
        background-color: $gray-soft;
        color: $gray-oslo;
      }
    }

    &.active {
      .page-link {
        background-color: $primary;
        color: $white-alabaster;
        border-color: $primary;
      }
    }
  }
}
