.news-detail-page {
  .news-img {
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center center;
    width: 100%;
    height: 300px;
    justify-content: center;
  }

  .description {
    .date {
      font-size: 12px;
    }

    .content {
      text-align: justify;
      font-size: 16px;
      white-space: pre-wrap;
    }
  }
}
