.banner-card {
  position: relative;
  width: 100%;
  height: 150px;
  background-size: cover;
  background-repeat: no-repeat;

  &:hover {
    margin-top: -3px;
    cursor: pointer;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 0.2rem $primary;
  }

  &__number {
    color: $primary;
    text-align: center;
    position: absolute;
    background-color: #fff;
    border-radius: 50%;
    font-family: quicksand-bold;
    top: 7px;
    left: 8px;
    width: 23px;
    height: 23px;
    line-height: 1.6;
  }

  &__delete {
    position: absolute;
    right: 0;
  }
}

.add-banner-button {
  background-color: $gray-oslo;
  border-radius: unset;
  width: 100%;
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;

  &:hover {
    margin-top: -3px;
  }
}
