.news-list-item-action {
  position: absolute !important;
  top: -40px;
  right: -15px;
}

.add-news-item-box {
  background: grey;
  border-radius: 10px;
  text-decoration: none;
}
