body {
  min-width: $xl; //this will ensure the width is always desktop width
}

.applayout {
  background-color: $page-background;
  padding-top: 100px;

  & > .container.app-container {
    @media (min-device-width: 0px) and (max-device-width: $md) {
      max-width: 95%;
    }
  }

  .user-panel {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: $black-mine-shaft;
    @media (max-width: $md) {
      color: white;
    }

    .user-image {
      width: 90px;
      height: 90px;
      border-radius: 45px;
      margin-right: 13px;

      @media (max-width: $md) {
        width: 30px;
        height: 30px;
      }
    }

    .username {
      font-weight: 700;
      font-size: 13px;
      word-break: break-all;
    }

    .subrole {
      font-size: 10px;
    }
  }

  &__content {
    width: 100%;
    min-height: 100vh;
    border: unset;
  }
}

.applayout.fixed-sidebar {
  min-height: 100vh;
  display: flex;
  padding-top: 0px;

  & > .sidebar {
    min-width: 20vw;
    height: 100vh;
    background-color: $sidebar-background;
    padding: 1rem;
    z-index: 99;

    .sidebar-wrapper {
      width: 100%;
    }
  }

  .main-content {
    @include scrollable();
    position: relative;
    height: 100vh;
    width: 100%;
    max-height: 100vh;
    overflow-y: scroll;
    padding: 8rem 4rem;
  }

  .navbar-fixed {
    position: fixed;
    z-index: 98;
    top: 0;
    left: 0;
    right: 0;
    background-color: $navbar-background;
    min-height: 3rem;
    margin-left: 20vw;
    padding: 0.5rem;
  }

  .other-item__title {
    color: $white !important;
  }
}
