.table-enlogy {
  table-layout: fixed;
  font-size: 12px;

  thead {
    border: unset;
    background-color: $primary-light;

    tr {
      th {
        border: unset;

        &:first-child {
          border-top-left-radius: 10px;
        }

        &:last-child {
          border-top-right-radius: 10px;
        }
      }
    }
  }

  tbody {
    tr {
      td {
        border: unset;
      }

      &:nth-child(even) {
        background-color: #f9f9f9;
      }

      &:nth-child(odd) {
        background-color: white;
      }

      &:last-child {
        td {
          &:first-child {
            border-bottom-left-radius: 10px;
          }

          &:last-child {
            border-bottom-right-radius: 10px;
          }
        }
      }
    }
  }
}
