.btn {
  border-radius: 20px;

  &.btn-white {
    background-color: #ffffff;
    border-color: #ffffff;
    color: #000000;
  }

  &.btn-outline-white {
    color: #ffffff;
    border-color: #ffffff;

    &:hover {
      background-color: #ffffff;
      color: #000000;
    }
  }

  &.no-radius {
    border-radius: unset;
  }

  &.btn-primary {
    background-color: $primary;

    &:active {
      background-color: darken($primary, 25%) !important;
      box-shadow: 0 0 0 0.2rem $primary !important;
    }
  }

  &.btn-outline-primary {
    color: $primary;
    border-color: $primary !important;
    outline: none;

    &:focus {
      box-shadow: 0 0 0 0.2rem $primary;
    }

    &:hover {
      background-color: $primary;
      color: white;
    }

    &:active {
      background-color: darken($primary, 25%) !important;
      box-shadow: 0 0 0 0.2rem $primary !important;
    }
  }
}
