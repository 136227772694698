$black-mine-shaft: #333333;
$black-tundora: #404040;
$gray-oslo: #818386;
$gray-soft: #eaeaea;
$gray-alto: #d9d9d9;
$green-japanese-laurel: #00b000;
$green-fruit-salad: #58a75b;
$green-malachite: #2cf63e;
$green-feta: #eafde7;
$white-alabaster: #f9f9f9;
$red-punch: #dc3545;
$blue-picton: #33b8f1;

$primary: #d3a647;
$primary-light: #f5d488;
$page-background: #f9f8f6;
$sidebar-background: #000000;
$navbar-background: #ffffff;
$white: $gray-alto;

$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
