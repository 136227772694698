$background-color: hsla(0, 0%, 85%, 0.9);

.form-light {
  .form-group {
    label {
      color: white;
      // padding-left: 20px;
    }
  }
}

.form-control {
  background-color: $background-color;
  // border-radius: 25px;
  padding: 6px 20px;

  &.invalid {
    border: 1px solid indianred;
  }

  &:focus {
    background-color: $background-color;
  }
}

.form-group .input-group {
  .input-group-prepend button {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
}

// custom react-select
.enlogy-react-select {
  .enlogy__control {
    // border: indianred 1px solid;
    border-radius: 25px;
    background-color: $background-color;
    box-shadow: unset;

    &:hover {
      border: 1px $gray-oslo solid;
    }

    &--is-focused {
      border: 1px $gray-oslo solid;
    }

    .enlogy__dropdown-indicator {
      color: $gray-oslo;
    }

    .enlogy__value-container {
      padding-left: 18px;
    }
  }

  .enlogy__option--is-focused {
    background-color: $primary;
    color: white;
  }

  &.invalid {
    .enlogy__control {
      border: 1px solid indianred;
    }
  }
}

// custom file input
.enlogy-file-input {
  &::-webkit-file-upload-button {
    visibility: hidden;
  }

  background-color: $background-color;
  padding: 4px 0px;

  &::before {
    content: 'Choose File';
    display: inline-block;
    background: white;
    border-radius: 60px;
    padding: 5px;
    margin-left: -9px;
    outline: none;
    white-space: nowrap;
    -webkit-user-select: none;
    cursor: pointer;
    font-weight: 700;
    font-size: 10pt;
    box-shadow: none;
  }

  &:hover::before {
    border-color: black;
  }

  &:focus {
    box-shadow: none;
  }
}
