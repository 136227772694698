.sidebar-wrapper {
  @media (min-width: $md) {
    margin-right: 20px;
  }

  ul.sidebar__list-menu {
    list-style: none;
    padding: 0;

    @media (max-width: $md) {
      padding-right: unset;
      overflow: scroll;
      height: 70vh;
    }

    .flip-vertical {
      transform: scaleY(-1);
    }

    li {
      padding: 5px 0;

      &.header {
        color: $white;
        font-size: 19px;
        margin-top: 20px;
        margin-bottom: 10px;
        padding: 0;
      }

      a {
        color: $white;
        text-decoration: none;
        white-space: nowrap;
        display: flex;
        width: 100%;
        padding: 0.5rem 1rem;
        border-radius: 0.5rem;

        &.active {
          color: $white;
          font-weight: 700;
          background-color: $primary;
        }

        &:hover {
          color: $white;
          background-color: $primary;
        }
      }

      .sidebar__menu-icon {
        width: 20px;
        height: 20px;
        margin-bottom: 5px;
        color: $white;
      }

      .sidebar__menu-name {
        padding: 0 10px;
      }
    }

    .other-item {
      &__icon {
        color: $white;
      }

      &__title {
        color: $gray-oslo;

        @media (max-width: $md) {
          color: white;
        }
      }
    }
  }

  &.navbar-menu-mobile {
    ul.sidebar__list-menu {
      li {
        a {
          color: white !important;
        }
      }
    }
  }
}
