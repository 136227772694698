$border-width: 2px;
$node-height: 160px;

.node-table {
  width: 100%;

  td {
    padding: 0px;
  }

  .node {
    display: flex;
    justify-content: center;
  }

  .node-line {
    td {
      width: 50%;
      height: 25px;
      padding: 0px;

      .line-border {
        min-height: 100%;

        &.right {
          border-right: 1px solid $gray-oslo !important;
        }

        &.left {
          border-left: 1px solid $gray-oslo !important;
        }

        &.left-top {
          border-left: 2px solid $gray-oslo !important;
          border-top: 2px solid $gray-oslo !important;
          border-top-left-radius: 10px;
        }

        &.right-top {
          border-right: 2px solid $gray-oslo !important;
          border-top: 2px solid $gray-oslo !important;
          border-top-right-radius: 10px;
        }
      }
    }
  }
}

/* ========================================================================== */
/* with library */
/* ========================================================================== */

$border-width: 2px;

.network-node__wrapper {
  display: flex;
  justify-content: center;
  position: relative;
  font-size: 11px;

  a {
    text-decoration: none;
    color: unset;
  }

  .network-node__controls {
    position: absolute;
    top: -30px;
    color: $primary;
  }

  .network-node {
    margin: 0 2px;
    position: relative;

    text-align: center;
    border: $border-width solid $primary;
    border-radius: 10px;
    width: 90px;
    min-height: $node-height;
    max-height: $node-height;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: 11px;
    font-weight: normal;

    &__header {
      border-radius: 8px 8px 0 0;
      color: white;
      background: $primary;
      white-space: nowrap;
      overflow: hidden;
      padding: 3px 0px;
    }

    &__footer {
      border-top: $border-width solid $primary;
      display: flex;

      .node-footer {
        &__left,
        &__right {
          width: 50%;
        }

        &__left {
          border-right: $border-width solid $primary;
        }
      }
    }

    // modifier
    &.add {
      background-color: $blue-picton;
      border-color: $blue-picton;
      color: white;
      justify-content: center;
    }

    &.empty {
      border: $border-width solid $gray-oslo;
      background: $gray-oslo;
      color: white;
      justify-content: center;
    }

    .node-blocked,
    .node-inactive,
    .node-pending {
      position: absolute;
      left: -2px;
      top: -2px;
      height: $node-height;
      width: 90px;
      border-radius: 10px;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;

      &.node-blocked {
        background-color: rgba(167, 82, 82, 0.8);
      }

      &.node-inactive {
        background-color: rgba(255, 174, 174, 0.8);
      }

      &.node-pending {
        background-color: rgba(255, 255, 0, 0.7);
        color: black;
      }
    }
  }
}

.network-container {
  position: relative;

  .network-loading {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    width: 100%;
    background: radial-gradient(white, transparent);
    z-index: 1;
    opacity: 0.4;
    top: 0;
    left: 0;
  }
}
