.step-wizard {
  list-style: none;
  color: white;
  padding-left: 0px;

  li {
    display: inline-block;

    &:first-child {
      .step-item {
        padding-left: 20px;
      }
    }

    .step-item {
      background-color: gray;
      width: 250px;
      height: 70px;
      padding-left: 50px;
      padding-top: 10px;

      position: relative;

      &::after {
        content: ' ';
        position: absolute;
        top: 0;
        left: 100%;
        height: 0;
        width: 0;
        border-right: 35px solid transparent;
        border-left: 35px solid gray;
        border-top: 35px solid transparent;
        border-bottom: 35px solid transparent;
        z-index: 19;
      }

      &.green {
        background-color: $primary;
        &::after {
          border-left-color: $primary;
        }
      }
    }
  }
}
